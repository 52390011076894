export class HttpError extends Error {
	message: string
	requestId: string
	specificError: any
	constructor(message: string, requestId: string, specificError?: any) {
		super()
		this.message = message
		this.requestId = requestId
		this.specificError = specificError
		try {
			Error.captureStackTrace(this, this.constructor)
		} catch (error) {
			return
		}
	}
}
