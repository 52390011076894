import liff from '@line/liff'
import { useRouter, useRoute } from 'vue-router'
import { HttpError } from '@/utils/http-error'
import { useI18n } from '@/plugins/i18n'
import { useModalStore } from '@/stores/modal.store'
import { useRegisterStore } from '@/stores/register.store'
import ModalClassic from '@/components/common/modal/MessageModal.vue'
import { ErrorData } from '@/utils/types/fetch.type'
import { addHours, getCurrentDate } from '@/utils/date'

export const useHandleErrorWithModal = () => {
  const { t, locale } = useI18n()
  const modalStore = useModalStore()
  const router = useRouter()
  const route = useRoute()

  const openUnauthorizedErrorModal = (
    requestId: string = '',
    error?: HttpError,
  ) => {
    const { error_detail } = error?.specificError as ErrorData
    const message = error_detail[0]?.reasons ?? undefined
    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.generalError.title'),
      content: message ?? t('modalContents.generalError.subTitle'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openGeneralErrorModal = (requestId: string = '') => {
    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.generalError.title'),
      content: t('modalContents.generalError.subTitle'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openBusinessValidationFailModal = (requestId: string) => {
    const registerStore = useRegisterStore()
    modalStore.openModal(ModalClassic, {
      icon: 'iconDuplicatePassport',
      header: registerStore.isThaiAccount
        ? t('modalContents.alreadyExists.existingIdCard.title')
        : t('modalContents.alreadyExists.existingMobileOrPassportNo.title'),
      content: registerStore.isThaiAccount
        ? (t('modalContents.alreadyExists.existingIdCard.subTitle') as string)
        : (t(
            'modalContents.alreadyExists.existingMobileOrPassportNo.subTitle',
          ) as string),
      requestId: registerStore.isThaiAccount ? requestId : undefined,
      buttonText: t('modal.ok'),
      isAllowedBackdropClose: true,
      callback: () => {
        router.replace({ path: '/user-form', query: route.query })
      },
    })
  }

  const openUserExistModal = (requestId: string) => {
    const registerStore = useRegisterStore()
    const subTitleString = t(
      'modalContents.alreadyExists.existingMobilePhone.subTitle',
    ) as string
    const htmlContent = subTitleString.replace(
      '{MobilePhone}',
      `<b style="color: #424242;">${registerStore.phoneNumber.value}</b>`,
    )

    modalStore.openModal(ModalClassic, {
      icon: 'mobilePhone',
      header: t('modalContents.alreadyExists.existingMobilePhone.title'),
      content: htmlContent,
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {
        router.replace({ path: '/', query: route.query })
      },
    })
  }

  const openErrorDopaFromCVA = (requestId: string, error?: HttpError) => {
    const { error_detail } = error?.specificError as ErrorData
    const message = error_detail[0]?.reasons ?? undefined
    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.invalidDopaFromCva.title'),
      content: message
        ? message
        : t('modalContents.invalidDopaFromCva.subTitle'),
      requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openNotSupportModal = () => {
    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.notSupport.title'),
      content: t('modalContents.notSupport.subTitle'),
      isAllowedBackdropClose: true,
      callback: () => {
        router.replace({ path: '/', query: route.query })
      },
    })
  }

  const openOtpLimitModal = (requestId: string) => {
    modalStore.openModal(ModalClassic, {
      icon: 'mobileOtp',
      header: t('modalContents.otp.title'),
      content: t('modalContents.otp.otpLimit'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openOtpExpiredModal = (requestId: string) => {
    modalStore.openModal(ModalClassic, {
      icon: 'mobileOtp',
      header: t('modalContents.otp.title'),
      content: t('modalContents.otp.otpExpires'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openOtpIncorrectModal = (requestId: string) => {
    modalStore.openModal(ModalClassic, {
      icon: 'mobileOtp',
      header: t('modalContents.otp.title'),
      content: t('modalContents.otp.otpInvalid'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const getHourUnit = (hour: number): 'AM.' | 'PM.' => {
    return hour < 12 ? 'AM.' : 'PM.'
  }

  const getDisplayHour = (hour: number): number => {
    if (locale.value === 'th') return hour
    return hour < 12 ? hour : hour - 12
  }

  const openOtpTooManyRequestRegisterModal = (requestId: string) => {
    const currentTime = getCurrentDate().toJSON()
    const nextHourTime = addHours(currentTime, 1)
    const hours = nextHourTime.getHours()
    const timeFormat = ` ${getDisplayHour(
      hours,
    )}:${nextHourTime.getMinutes()} ${
      locale.value === 'th' ? 'น.' : getHourUnit(hours)
    }`

    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.tooManyRequestError.title'),
      content: t('modalContents.tooManyRequestError.subTitle') + timeFormat,
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const openOtpTooManyRequestOtpModal = (requestId: string) => {
    modalStore.openModal(ModalClassic, {
      icon: 'warning',
      header: t('modalContents.generalError.title'),
      content: t('modalContents.generalError.subTitle'),
      requestId: requestId,
      isAllowedBackdropClose: true,
      callback: () => {},
    })
  }

  const handleError = (error: unknown) => {
    if (error instanceof HttpError) {
      switch (error.message) {
        case 'line_id_token_is_invalid': {
          liff.logout()
          liff.login()
          break
        }
        case 'customer_info_is_invalid':
        case 'pid_or_laser_is_invalid':
        case 'id_card_invalid_from_dopa':
          openErrorDopaFromCVA(error.requestId, error)
          break
        case 'business_validation_failed':
          openBusinessValidationFailModal(error.requestId)
          break
        case 'mobileno_exist': {
          openUserExistModal(error.requestId)
          break
        }
        case 'not_support': {
          openNotSupportModal()
          break
        }
        case 'attempt_count_is_over_limits': {
          openOtpLimitModal(error.requestId)
          break
        }
        case 'otp_is_expired': {
          openOtpExpiredModal(error.requestId)
          break
        }
        case 'otp_is_incorrect': {
          openOtpIncorrectModal(error.requestId)
          break
        }
        case 'too_many_requests': {
          openOtpTooManyRequestRegisterModal(error.requestId)
          break
        }
        case 'too_many_request_send_otp': {
          openOtpTooManyRequestOtpModal(error.requestId)
          break
        }
        case 'unauthorized':
          openUnauthorizedErrorModal(error.requestId, error)
          break
        case 'general_error':
        default: {
          openGeneralErrorModal(error.requestId)
        }
      }
    } else {
      openGeneralErrorModal('')
      console.error(error)
    }
  }

  return { handleError }
}
