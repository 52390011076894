<template>
  <div
    class="fixed inset-0 m-auto z-30 top-0 left-0 right-0 bottom-0 bg-white min-h-min max-h-max text-center rounded-2xl flex items-center justify-center w-[300px] xxs:w-[327px] sm:w-[365px]"
  >
    <div class="flex flex-col p-5">
      <Transition
        enter-from-class="opacity-20"
        enter-active-class="transition duration-400"
        leave-active-class="opacity-0 duration-400"
      >
        <div
          v-if="copied"
          class="fixed z-10 bg-black text-white text-sm font-medium p-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md"
        >
          {{ $t('modalContents.copiedRefCode') }}
        </div>
      </Transition>
      <div>
        <img class="h-16 md:h-20 lg:h-24 mb-2 mx-auto" :src="iconComponent" />
        <p class="text-gray-700 font-bold text-2xl">{{ header }}</p>
      </div>
      <div
        class="py-2 max-h-48 overflow-y-auto text-gray-550 font-medium text-base min-w-[287px]"
        :class="messageAlign[props.messageAlign]"
        v-html="content"
      ></div>
      <div
        v-if="requestId"
        class="my-2 text-gray-550 text-[10px] md:text-xs font-medium"
      >
        <span>{{ $t('modalContents.refUUID') + ' ' + requestId }}</span>
        <fa
          icon="fa-regular fa-copy"
          class="h-5 mx-3 -mb-1 cursor-pointer active:text-gray-900"
          @click="copy(requestId)"
        ></fa>
      </div>
      <div class="mt-4">
        <Button
          class="px-6 w-full h-12"
          rounded="medium"
          :value="
            buttonText
              ? buttonText
              : $t('modalContents.generalError.closeButton')
          "
          @click="closeModal"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useModalStore } from '@/stores/modal.store'
import Button from '@/components/common/button/Button.vue'
import { IconName, icons } from '@/utils/icon'

const modalStore = useModalStore()
const { copy, copied } = useClipboard()

const messageAlign = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
}

const props = withDefaults(
  defineProps<{
    isAllowedBackdropClose?: boolean
    icon: IconName
    header: string
    content: string
    messageAlign: keyof typeof messageAlign
    buttonText?: string
    requestId?: string
    callback?: () => void
  }>(),
  {
    messageAlign: 'center',
  },
)

const iconComponent = computed(() => icons[props.icon])

const closeModal = async () => {
  if (props.callback) {
    props.callback()
  }
  modalStore.closeModal()
}
</script>
