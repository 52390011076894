<template>
  <button
    class="py-2 rounded-full transition duration-300 outline-none focus:outline-none text-base font-medium"
    :class="[
      props.class ? props.class : 'px-6',
      state.isLoading || disabled
        ? colors[props.type].disabledColor
        : colors[props.type].color,
      rounded[props.rounded],
    ]"
    :disabled="props.disabled || state.isLoading"
    @click="handleClick"
  >
    <div v-if="state.isLoading" class="w-full h-4 mt-2 flex justify-center">
      <div
        class="w-3 h-3 mx-1 rounded-full float-left bg-teal-400 animate-[bounce-loading_1s_infinite_ease-in-out]"
      ></div>
      <div
        class="w-3 h-3 mx-1 rounded-full float-left bg-teal-400 animate-[bounce-loading_1s_infinite_ease-in-out_0.25s]"
      ></div>
      <div
        class="w-3 h-3 mx-1 rounded-full float-left bg-teal-400 animate-[bounce-loading_1s_infinite_ease-in-out_0.5s]"
      ></div>
    </div>
    <template v-else>
      <fa v-if="props.icon" :icon="props.icon" class="w-4 h-4 mx-2"></fa>
      <span>{{ props.value }}</span>
    </template>
  </button>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'

const colors = {
  primary: {
    color: 'bg-primary hover:bg-green-700 text-white border-0',
    disabledColor: 'bg-green-200 text-white cursor-not-allowed',
  },
  danger: {
    color: 'bg-danger hover:bg-red-700 text-white border-0',
    disabledColor: 'bg-red-400 text-white cursor-not-allowed',
  },
  dismiss: {
    color: 'bg-gray hover:bg-gray-250 border-0',
    disabledColor: 'bg-gray-100 text-white cursor-not-allowed',
  },
  selected: {
    color: 'bg-green-100 text-primary border border-primary cursor-default',
    disabledColor: 'bg-gray-100 text-white cursor-not-allowed',
  },
  unselected: {
    color: 'bg-white hover:bg-gray-25 text-gray-500 border border-gray-100',
    disabledColor: 'bg-gray-100 text-white cursor-not-allowed',
  },
}

const rounded = {
  primary: 'rounded',
  medium: 'rounded-md',
  large: 'rounded-lg',
  full: 'rounded-full',
  none: 'rounded-none',
}

const props = withDefaults(
  defineProps<{
    value: string
    class?: string
    type?: keyof typeof colors
    rounded?: keyof typeof rounded
    icon?: string
    disabled?: boolean
    onClick?: () => void | Promise<void>
  }>(),
  { type: 'primary', rounded: 'full', disabled: false },
)

const state = reactive({
  isLoading: false,
})

const handleClick = async () => {
  if (props.onClick && !props.disabled && !state.isLoading) {
    state.isLoading = true
    await props.onClick()
    state.isLoading = false
  }
}

defineExpose({
  click: handleClick,
})
</script>
