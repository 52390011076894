import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import 'dayjs/locale/th'

dayjs.extend(customParseFormat)
dayjs.extend(buddhistEra)

/**
 * Add a specified number of hours to a given date string.
 *
 * @param {string} date - The date string to which hours will be added.
 * @param {number} hours - The number of hours to add (can be negative).
 * @return {Date} The new Date object after adding the specified hours.
 */
export const addHours = (date: string, hours: number): Date => {
  return dayjs(date).add(Math.abs(hours), 'hour').toDate()
}

/**
 * Adds a specified number of days to a given date.
 *
 * @param {string} date - The date to add days to, in string format.
 * @param {number} days - The number of days to add.
 * @returns {Date} - The resulting date after adding the specified number of days.
 */
export const addDate = (date: string, days: number): Date => {
  return dayjs(date).add(Math.abs(days), 'day').toDate()
}

/**
 * Subtracts the specified number of years from the given date.
 *
 * @param {string} date - The date to subtract years from.
 * @param {number} years - The number of years to subtract.
 * @return {Date} The new date after subtracting the specified years.
 */
export const subtractYears = (date: string, years: number): Date => {
  return dayjs(date).subtract(Math.abs(years), 'year').toDate()
}

/**
 * Returns the current date as a JavaScript Date object.
 *
 * @return {Date} The current date.
 */
export const getCurrentDate = (): Date => {
  return dayjs().toDate()
}

/**
 * Converts the input date string to a Date object using the specified format.
 *
 * @param {string} date - the input date string
 * @param {string} format - the format of the input date string
 * @return {Date} the Date object representing the input date string
 */
export const getDateWithFormat = (date: string, format: string): Date => {
  return dayjs(date, format).toDate()
}

/**
 * A function that formats a given date into a specified format.
 *
 * @param {Date | string} date - The date to be formatted.
 * @param {string} format - The format string specifying how the date should be formatted.
 * @return {string} The formatted date as a string.
 */
export const formatDate = (date: Date | string, format: string): string => {
  const parsedDate = dayjs(date)
  const formattedDate = parsedDate.format(format)
  return formattedDate
}
