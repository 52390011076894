import { defineStore } from 'pinia'
import { ExtractComponentProps } from '@/utils/types/component.type'

export const useModalStore = defineStore('modal-store', {
	state: () =>
		<
		{
			isOpen: boolean
			component?: any
			props?: any
			callback?: (props?: any) => void
		}
		>{
			isOpen: false,
		},
	actions: {
		closeModal(): void {
			this.isOpen = false
		},
		openModal<TComponent extends abstract new (...args: any) => any>(
			component: TComponent,
			props?: ExtractComponentProps<TComponent> & {
				isAllowedBackdropClose: boolean
				callback?: (props?: any) => void
			},
		): void {
			this.isOpen = true
			if (props) {
				this.props = props
			}
			this.component = component
		},
	},
	getters: {},
})
